.card {
  display: inline-block;
  box-shadow: rgb(0, 140, 155, 0.3) 0px 0px 0px 3px;
  padding: 8px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 2rem;
  &:hover {
    position: relative;
    bottom: 5px;
    box-shadow: rgb(0, 140, 155, 0.9) 0px 0px 0px 3px;
  }
}
.img-container {
  height: 200px;
  width: 100%;
}
.img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
}
.header {
  background-color: #000;
  color: var(--white);
  font-size: 1.8rem;
  padding: 2px 2px;
  height: 5.9rem;
  font-weight: 400;
}
.footer {
  background-color: var(--light);
  color: var(--dark);
  padding: 10px;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 400;
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
}
.footer-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.6rem;
  height: 5.2rem;
  &:last-child {
    margin: 10px 0;
  }
}
.primary-text {
  color: var(--primary);
}

//larger card
.large {
  .header {
    font-size: 2.8rem;
    height: 4.5rem;
    padding-left: 10px;
  }
  .img-container {
    height: 270px;
  }
  .footer {
    font-size: 1.9rem;
    padding: 15px 20px;
  }
}
//small card
.small {
  display: flex;
  background-color: var(--light);
  padding: 8px;
  height: 122px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .img-container {
    width: 50%;
    height: 100%;
    flex: 0 0 auto;
    padding-right: 5px;
    .img {
      border-radius: 0;
    }
  }
  .header {
    color: var(--dark);
    height: 100%;
    background-color: transparent;
  }
  .footer {
    display: none;
  }
}
