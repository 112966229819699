.partner-slide > * {
  height: 100px !important;
  position: relative;
}
.arrow-btn {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  color: var(--white);
  font-size: 50px;
  cursor: pointer;
  &:last-child {
    right: 120px;
  }
  &:first-child {
    left: 120px;
  }
  &:hover {
    background-color: var(--red);
  }
}

