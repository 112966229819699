.footer {
  background-color: #111;
  color: var(--white);
  display: flex;
  flex-wrap: wrap;
  padding: 30px 6px 5px;
}
///LOGO
.logo {
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
}
.logo-img {
  height: 50px;
}
.logo-name {
  font-size: 30px;
  font-weight: 400;
}
///INFO
.info-heading {
  font-size: 2rem;
}
.info-list {
  font-size: 1.8rem;
  color: var(--gray);
}
.info-item {
  margin-top: 4px;
}
.icon {
  margin-right: 8px;
}
.item-text {
  margin-top: 25px;
}
.item-link {
  display: inline-block;
  color: var(--white);
  background-color: var(--blue);
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 1.8rem;
  i {
    margin-right: 4px;
  }
  &:hover {
    opacity: 0.9;
  }

}
///COPYRIGHT
.copyright {
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-style: italic;
  color: var(--gray);
}
