.heading {
  color: var(--primary);
  margin-bottom: 4rem;
  .underline {
    display: block;
    border-radius: 3px;
    width: 50%;
    height: 10px;
    background-color: var(--primary);
  }
}
