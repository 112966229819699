.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: rgba(60, 64, 67, 0.2) 1px solid;
  margin: 4rem 0;
  padding: 5px;
  h2 {
    margin-right: 3rem;
  }
}
.btn {
  display: block;
  min-width: 105px;
  span {
    padding: 10px;
    font-size: 1.7rem;
  }
}
.description {
    margin-top: 2rem;
}
.text-large {
  font-weight: 500;
  margin: 1rem 0;
}
.text {
  padding-left: 3rem;
  font-size: 1.8rem;
  color: var(--dark);
}