.wrap {
  background-color: rgb(0, 0, 0, 0.9);
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  .nav-item {
    margin: 0 4px;
    font-size: 2.2rem;
    text-align: center;
    min-width: 100px;
    padding: 4px 15px;
    color: var(--white);
    border-radius: 10px;
    .icon {
      position: relative;
      left: 4px;
      bottom: 2px;
    }
    &:hover {
      background-color: var(--primary);
    }
  }
}
.active {
  background-color: var(--primary);
}
.drop {
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 30px;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent var(--white) transparent;
    z-index: 99;
  }
  .drop-item {
    display: block;
    padding: 8px 12px;
    color: var(--dark);
    font-size: 1.9rem;
    &:hover {
      cursor: pointer;
      background-color: var(--primary);
      color: var(--white);
    }
  }
}
