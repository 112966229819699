.topbar {
  width: 100%;
}
.topbar-1 {
  background-color: var(--dark);
  color: var(--white);
  font-size: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  padding: 0 8px;
}
.item {
  width: 50%;
  text-align: center;
}
.item-text {
  &:first-child {
    margin-right: 8px;
  }
}
.item-link {
  display: inline-block;
  color: var(--white);
  background-color: var(--blue);
  padding: 2px 10px;
  border-radius: 5px;
  i {
    margin-right: 4px;
  }
  span {
    font-size: 1.8rem;
  }
  &:hover {
    opacity: 0.9;
  }
}
.topbar-2 {
  padding: 15px 8px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.logo {
  width: 330px;
  display: flex;
  align-items: center;
}
.logo-img {
  width: 130px;
}
.logo-name {
  color: var(--primary);
  font-size: 60px;
}
.info {
  display: flex;
  align-items: center;
}
.info-item {
  padding: 0 16px;
  &:nth-child(2),
  &:last-child {
    border-left: 2px solid rgb(0, 0, 0, 0.2);
  }
}
.item-title {
  text-align: center;
  margin-bottom: 4px;
  font-size: 2rem;
}
.item-description {
  font-size: 1.9rem;
  color: var(--gray);
  text-align: center;
}
