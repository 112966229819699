.item {
  margin-bottom: 10px;
  border: 1px solid;
  border: rgba(60, 64, 67, 0.2) 1px solid;
  padding: 10px 30px;
  &:not(:first-child) {
    display: flex;
    align-items: center;
  }
}
.heading {
  margin-right: 30px;
  font-size: 2.2rem;
  &::after {
    content: ' :';
  }
}
.icon {
  color: var(--primary);
  margin-right: 8px;
}
.content {
  font-size: 2rem;
}
.link {
  color: var(--primary);
  font-size: 2rem;
  text-decoration: underline;
  &:hover {
    color: var(--red);
  }
}
.map {
  border: none;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
}
