.heading {
  color: var(--primary);
}

.img-container {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  &:nth-child(4) {
    margin-top: 5rem;
  }
}
.img {
  width: 100%;
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
