p {
  font-size: 1.7rem;
  color: var(--gray);
}

.heading {
  color: var(--primary);
  font-size: 2.5rem;
  margin: 20px 0 10px;
}
.slogan {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: var(--white);
  background: #12c2e9; 
  background: -webkit-linear-gradient(to right, #12c2e9, #c471ed, #f64f59); 
  background: linear-gradient(
    to right,
    #12c2e9,
    #c471ed,
    #f64f59
  ); 
}
