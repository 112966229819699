@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600;700;800;900&family=Roboto:wght@300;400;500;700&display=swap');
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #008c9b;
  --secondary: #262626;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #99999933;
  --dark: #343a40;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 62.5%;
}
body {
  font-family: 'Inter', sans-serif;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}
a {
  text-decoration: none;
}
.btn-home {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--white);
  &:hover {
    background-color: var(--red);
  }
}
.container {
  max-width: 1200px;
  margin: 30px auto;
}
h1 {
  font-size: 4.2rem;
}
h2 {
  font-size: 3.5rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2rem;
}
h5 {
  font-size: 1.8rem;
}
p {
  font-size: 1.8rem;
  line-height: 1.6;
}
section {
  margin-bottom: 10rem;
}
.right-icon {
  color: var(--primary);
  margin-right: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  font-size: 1.3rem;
  &:hover {
    z-index: 2;
    cursor: pointer;
    color: #0a58ca;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 1rem 2rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.carousel {
  padding-bottom: 50px;
  background-color: transparent;
}
.control-dots {
  .dot {
    background-color: var(--primary) !important;
    height: 13px !important;
    width: 13px !important;
  }
}
