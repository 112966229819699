.sidebar {
  padding: 24px 10px;
}
.item {
  width: 100%;
  margin-bottom: 48px;
}
.heading {
  text-transform: uppercase;
  font-size: 3rem;
  color: var(--dark);
  margin: 20px 0 10px;
}
.nav {
  border-top: rgba(60, 64, 67, 0.2) 1px solid;
  padding: 0;
}
.nav-item {
  text-transform: uppercase;
  list-style: none;
  width: 100%;
  padding: 8px 25px;
  font-size: 1.7rem;
  font-weight: 400;
  color: var(--primary);
  border: rgba(60, 64, 67, 0.2) 1px solid;
  border-top: transparent;
  &:hover {
    background-color: rgba(60, 64, 67, 0.1);
    text-decoration: underline;
    cursor: pointer;
  }
}

.image-container {
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 10px;
}
.img {
  width: 100%;
  border-radius: 5px;
}
.keyword-list {
  display: flex;
  flex-wrap: wrap;
}
.keyword-item {
  color: var(--primary);
  display: flex;
  padding: 10px;
  font-size: 1.7rem;
  border: rgba(60, 64, 67, 0.2) 1px solid;
  margin: 0 6px 6px 0;
  &:hover {
    background-color: var(--primary);
    color: var(--white);
  }
}
